export const programData = [
  {
    'id': 'fxb-i01',  // 作业路由ID
    'type': '',
    'routerLink': '/fxb-i01' // implementation-routes作业路由id
  },
  {
    'id': 'fxiq001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxiq001' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq093',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq093' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq095',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq095' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi042',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi042' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq096',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq096' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq097',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq097' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq098',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq098' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq099',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq099' // implementation-routes作业路由id
  },
  //   {
  //     'id': 'fxeq100',  // 作业路由ID
  //     'type': '',
  //     'routerLink': '/fxeq100' // implementation-routes作业路由id
  //   },
  {
    'id': 'fxeq101',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq101' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq102',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq102' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq103',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq103' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq104',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq104' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq105',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq105' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq106',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq106' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq111',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq111' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq118',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq118' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq107',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq107' // implementation-routes作业路由id
  },
  {
    'id': 'fxiq002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxiq002' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi301',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi301' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd001' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd007' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd067',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd067' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd008',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd008' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd031',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd031' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd009',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd009' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd010',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd010' // implementation-routes作业路由id
  },
  {
    'id': 'fxxi001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxi001' // implementation-routes作业路由id
  },
  {
    'id': 'fxxi002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxi002' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq068',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq068' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq069',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq069' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq070',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq070' // implementation-routes作业路由id
  },
  {
    'id': 'fxad003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxad003' // implementation-routes作业路由id
  },
  {
    'id': 'fxci015',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci015' // implementation-routes作业路由id
  },
  {
    'id': 'fxci016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci016' // implementation-routes作业路由id
  },
  {
    'id': 'fxci017',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci017' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi035',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi035' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi036',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi036' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi080',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi080' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi081',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi081' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi037',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi037' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi019',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi019' // implementation-routes作业路由id
  },
  {
    'id': 'fxai002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai002' // implementation-routes作业路由id
  },
  {
    'id': 'fxai004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai004' // implementation-routes作业路由id
  },
  {
    'id': 'fxai099',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai099' // implementation-routes作业路由id
  },
  {
    'id': 'fxai031',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai031' // implementation-routes作业路由id
  },
  {
    'id': 'fxai033',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai033' // implementation-routes作业路由id
  },
  {
    'id': 'fxai005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai005' // implementation-routes作业路由id
  },
  {
    'id': 'fx-666',
    'type': '',
    'routerLink': '/fx-666' // 对应作业ID
  },
  {
    'id': 'fxgrid',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgrid' // implementation-routes作业路由id
  },
  {
    'id': 'fxai001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai001' // implementation-routes作业路由id
  },
  {
    'id': 'fxaq003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxaq003' // implementation-routes作业路由id
  },
  {
    'id': 'fxaq903',  // 作业路由ID
    'type': '',
    'routerLink': '/fxaq903' // implementation-routes作业路由id
  },
  {
    'id': 'fxaq904',  // 作业路由ID
    'type': '',
    'routerLink': '/fxaq904' // implementation-routes作业路由id
  },
  {
    'id': 'fxaq033',  // 作业路由ID
    'type': '',
    'routerLink': '/fxaq033' // implementation-routes作业路由id
  },
  {
    'id': 'fxhq001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhq001' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd010',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd010' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd011' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd012' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq032',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq032' // implementation-routes作业路由id
  },
  {
    'id': 'fxgq002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgq002' // implementation-routes作业路由id
  },
  {
    'id': 'fxgq012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgq012' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd009',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd009' // implementation-routes作业路由id
  },
  {
    'id': 'fxip001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxip001' // implementation-routes作业路由id
  },
  {
    'id': 'fxii010',  // 作业路由ID
    'type': '',
    'routerLink': '/fxii010' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq003' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq014' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi030',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi030' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi031',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi031' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi032',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi032' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi034',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi034' // implementation-routes作业路由id
  },
  {
    'id': 'fxed040',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed040' // implementation-routes作业路由id
  },
  {
    'id': 'fxed041',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed041' // implementation-routes作业路由id
  },
  {
    'id': 'fxai034',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai034' // implementation-routes作业路由id
  },
  {
    'id': 'fxai035',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai035' // implementation-routes作业路由id
  },
  {
    'id': 'fxai036',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai036' // implementation-routes作业路由id
  },
  {
    'id': 'fxed042', // 作业路由ID
    'type': '',
    'routerLink': '/fxed042' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq031',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq031' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq033', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq033' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq038', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq038' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq060', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq060' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq066', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq066' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq073', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq073' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq074', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq074' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq075', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq075' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq076', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq076' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq122', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq122' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq081', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq081' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq082', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq082' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq083', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq083' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq084', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq084' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq085', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq085' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq087', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq087' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq088', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq088' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq089', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq089' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi302', // 作业路由ID
    'type': '',
    'routerLink': '/fxdi302' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq040',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq040' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq041', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq041' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq042', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq042' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq044', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq044' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq045', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq045' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq043', // 作业路由ID
    'type': '',
    'routerLink': '/fxeq043' // implementation-routes作业路由id
  },
  {
    'id': 'fxai006',  // 作业路由ID
    'type': 'program',
    'routerLink': '/fxai006' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd101',  // 作业路由ID
    'type': 'program',
    'routerLink': '/fxgd101' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd102',  // 作业路由ID
    'type': 'program',
    'routerLink': '/fxgd102' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd103',  // 作业路由ID
    'type': 'program',
    'routerLink': '/fxgd103' // implementation-routes作业路由id
  },
  {
    'id': 'fxai007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai007' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd303',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd303' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd036',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd036' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd037',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd037' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi038',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi038' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi039',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi039' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi040',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi040' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi041',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi041' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi045',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi045' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi046',  // 作业路由ID-下一年销售计划
    'type': '',
    'routerLink': '/fxdi046' // implementation-routes作业路由id
  },
  {
    'id': 'fxdk001',  // 作业路由ID-门店装修历史记录
    'type': '',
    'routerLink': '/fxdk001' // implementation-routes作业路由id
  },
  {
    'id': 'fxai010',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai010' // implementation-routes作业路由id
  },
  {
    'id': 'fxai011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai011' // implementation-routes作业路由id
  },
  {
    'id': 'fxai012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai012' // implementation-routes作业路由id
  },
  {
    'id': 'fxei003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxei003' // implementation-routes作业路由id
  },
  {
    'id': 'fxei004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxei004' // implementation-routes作业路由id
  },
  {
    'id': 'fxai013',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai013' // implementation-routes作业路由id
  },
  {
    'id': 'fxai014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai014' // implementation-routes作业路由id
  },
  {
    'id': 'fxai015',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai015' // implementation-routes作业路由id
  },
  {
    'id': 'fxai021',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai021' // implementation-routes作业路由id
  },
  {
    'id': 'fxai022',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai022' // implementation-routes作业路由id
  },
  {
    'id': 'fxai023',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai023' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi002' // implementation-routes作业路由id
  },
  {
    'id': 'fxgi002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgi002' // implementation-routes作业路由id
  },
  {
    'id': 'fxgi003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgi003' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi003' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi005' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi006' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi010',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi010' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi011' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi012' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi013',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi013' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi014' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi016' // implementation-routes作业路由id
  },
  {
    'id': 'fxgp001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgp001' // implementation-routes作业路由id
  },
  {
    'id': 'fxfi002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxfi002' // implementation-routes作业路由id
  },
  {
    'id': 'fxxi003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxi003' // implementation-routes作业路由id
  },
  {
    'id': 'fxxi004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxi004' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd018',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd018' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd066',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd066' // implementation-routes作业路由id
  },
  {
    'id': 'fxhd002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhd002' // implementation-routes作业路由id
  },
  {
    'id': 'fxhd003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhd003' // implementation-routes作业路由id
  },
  {
    'id': 'fxhd004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhd004' // implementation-routes作业路由id
  },
  {
    'id': 'fxhd005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhd005' // implementation-routes作业路由id
  },
  {
    'id': 'fxhd007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhd007' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi024',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi024' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi025',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi025' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi026',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi026' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi027',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi027' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi028',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi028' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi029',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi029' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi029',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi029' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd008',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd008' // implementation-routes作业路由id
  },
  {
    'id': 'fxci013',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci013' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi033',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi033' // implementation-routes作业路由id
  },
  {
    'id': 'fxhq002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhq002' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi030',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi030' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd006', // 作业路由ID
    'type': '',
    'routerLink': '/fxgd006' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi031',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi031' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi032',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi032' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi033',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi033' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi064',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi064' // implementation-routes作业路由id
  },
  {
    'id': 'fxed006', // 作业路由ID
    'type': '',
    'routerLink': '/fxed006' // implementation-routes作业路由id
  },
  {
    'id': 'fxed056', // 作业路由ID
    'type': '',
    'routerLink': '/fxed056' // implementation-routes作业路由id
  },
  {
    'id': 'fxed026', // 作业路由ID
    'type': '',
    'routerLink': '/fxed026' // implementation-routes作业路由id
  },
  {
    'id': 'fxed027', // 作业路由ID
    'type': '',
    'routerLink': '/fxed027' // implementation-routes作业路由id
  },
  {
    'id': 'fxai024',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai024' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi001' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi025',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi025' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd001' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd002' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi001' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi004' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi009',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi009' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi011' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi012' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi013',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi013' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi014' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi015',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi015' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi016' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd007' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi020',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi020' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi021',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi021' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi022',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi022' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi023',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi023' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi050',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi050' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi053',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi053' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi054',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi054' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi082',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi082' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi083',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi083' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi086',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi086' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi087',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi087' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi088',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi088' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi089',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi089' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi090',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi090' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi091',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi091' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi093',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi093' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi099',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi099' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi092',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi092' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi093',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi093' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi095',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi095' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi096',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi096' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi097',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi097' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi105',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi105' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi106',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi106' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd083',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd083' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi070',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi070' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi103',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi103' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi104',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi104' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi101',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi101' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd086',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd086' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd087',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd087' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd089',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd089' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi071',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi071' // implementation-routes作业路由id
  },
  {
    'id': 'fxhq003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhq003' // implementation-routes作业路由id
  },
  {
    'id': 'fxhq004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhq004' // implementation-routes作业路由id
  },
  {
    'id': 'fxhq005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhq005' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi055',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi055' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi100',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi100' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi200',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi200' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd003' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd005' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd006' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd007' // implementation-routes作业路由id
  },
  {
    'id': 'fxei002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxei002' // implementation-routes作业路由id
  },
  // {
  //   'id': 'fxdd010',  // 作业路由ID
  //   'type': '',
  //   'routerLink': '/fxdd010' // implementation-routes作业路由id
  // },
  {
    'id': 'fxdd011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd011' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd012' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd014' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd071',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd071' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd072',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd072' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd073',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd073' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd074',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd074' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi300',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi300' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi400',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi400' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi401',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi401' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi402',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi402' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi403',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi403' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi404',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi404' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi406',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi406' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi007' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi008',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi008' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi023',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi023' // implementation-routes作业路由id
  },
  {
    'id': 'fxai025',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai025' // implementation-routes作业路由id
  },
  {
    'id': 'fxai026',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai026' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi021',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi021' // implementation-routes作业路由id
  },
  {
    'id': 'fxci005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci005' // implementation-routes作业路由id
  },
  {
    'id': 'fxci006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci006' // implementation-routes作业路由id
  },
  {
    'id': 'fxci007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci007' // implementation-routes作业路由id
  },
  {
    'id': 'fxci008',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci008' // implementation-routes作业路由id
  },
  {
    'id': 'fxci001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci001' // implementation-routes作业路由id
  },
  {
    'id': 'fxci009',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci009' // implementation-routes作业路由id
  },
  {
    'id': 'fxci018',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci018' // implementation-routes作业路由id
  },
  {
    'id': 'fxci010',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci010' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq053',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq053' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq100',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq100' // implementation-routes作业路由id
  },
  {
    'id': 'fxci011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci011' // implementation-routes作业路由id
  },
  {
    'id': 'fxci002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci002' // implementation-routes作业路由id
  }, {
    'id': 'fxdi002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi002' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi005' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi056',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi056' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi006' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi405',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi405' // implementation-routes作业路由id
  },
  {
    'id': 'fxed001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed001' // implementation-routes作业路由id
  },
  {
    'id': 'fxei001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxei001' // implementation-routes作业路由id
  },
  {
    'id': 'fxei005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxei005' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq001' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq002' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq003' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq064',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq064' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq067',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq067' // implementation-routes作业路由id
  },
  {
    'id': 'fxci003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci003' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi005' // implementation-routes作业路由id
  },
  {
    'id': 'fxci004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci004' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq300',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq300' // implementation-routes作业路由id
  },
  {
    'id': 'fxed002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed002' // implementation-routes作业路由id
  },
  {
    'id': 'fxgi001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgi001' // implementation-routes作业路由id
  },
  {
    'id': 'fxhi005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhi005' // implementation-routes作业路由id
  },
  {
    'id': 'fxhp001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhp001' // implementation-routes作业路由id
  },
  {
    'id': 'fxhp002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhp002' // implementation-routes作业路由id
  },
  {
    'id': 'fxhi002', // 作业路由ID
    'type': '',
    'routerLink': '/fxhi002' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi201',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi201' // implementation-routes作业路由id
  },
  {
    'id': 'fxii001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxii001' // implementation-routes作业路由id
  },
  {
    'id': 'fxii002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxii002' // implementation-routes作业路由id
  },
  {
    'id': 'fxii003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxii003' // implementation-routes作业路由id
  },
  {
    'id': 'fxii004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxii004' // implementation-routes作业路由id
  },
  {
    'id': 'fxii006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxii006' // implementation-routes作业路由id
  },
  {
    'id': 'fxii009',  // 作业路由ID
    'type': '',
    'routerLink': '/fxii009' // implementation-routes作业路由id
  },
  {
    'id': 'fxhi001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhi001' // implementation-routes作业路由id
  },
  {
    'id': 'fxfd001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxfd001' // implementation-routes作业路由id
  },
  {
    'id': 'fxhd001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhd001' // implementation-routes作业路由id
  },
  {
    'id': 'fxed010',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed010' // implementation-routes作业路由id
  },
  {
    'id': 'fxed011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed011' // implementation-routes作业路由id
  },
  {
    'id': 'fxed015',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed015' // implementation-routes作业路由id
  },
  {
    'id': 'fxed016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed016' // implementation-routes作业路由id
  },
  {
    'id': 'fxed012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed012' // implementation-routes作业路由id
  },
  {
    'id': 'fxed017',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed017' // implementation-routes作业路由id
  },
  {
    'id': 'fxed018',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed018' // implementation-routes作业路由id
  },
  {
    'id': 'fxed019',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed019' // implementation-routes作业路由id
  },
  {
    'id': 'fxed045',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed045' // implementation-routes作业路由id
  },
  {
    'id': 'fxed046',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed046' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi050',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi050' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi051',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi051' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi052',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi052' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi060',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi060' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi061',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi061' // implementation-routes作业路由id
  },
  {
    'id': 'fxed003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed003' // implementation-routes作业路由id
  },
  {
    'id': 'fxed004', // 作业路由ID
    'type': '',
    'routerLink': '/fxed004' // implementation-routes作业路由id
  },
  {
    'id': 'fxed005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed005' // implementation-routes作业路由id
  },
  {
    'id': 'fxed007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed007' // implementation-routes作业路由id
  },
  {
    'id': 'fxed008',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed008' // implementation-routes作业路由id
  },
  {
    'id': 'fxed047',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed047' // implementation-routes作业路由id
  },
  {
    'id': 'fxed009',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed009' // implementation-routes作业路由id
  },
  {
    'id': 'fxed021',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed021' // implementation-routes作业路由id
  },
  {
    'id': 'fxed030',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed030' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd002' // implementation-routes作业路由id
  },
  {
    'id': 'fxci014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxci014' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd003' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd004' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd005' // implementation-routes作业路由id
  },
  {
    'id': 'fxgq001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgq001' // implementation-routes作业路由id
  },
  {
    'id': 'fxgq111',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgq111' // implementation-routes作业路由id
  },
  {
    'id': 'fxgq112',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgq112' // implementation-routes作业路由id
  },
  {
    'id': 'fxgd100',  // 作业路由ID
    'type': '',
    'routerLink': '/fxgd100' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi010',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi010' // implementation-routes作业路由id
  },
  {
    'id': 'fxai032',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai032' // implementation-routes作业路由id
  },
  {
    'id': 'fxai039',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai039' // implementation-routes作业路由id
  },
  {
    'id': 'fxai040',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai040' // implementation-routes作业路由id
  },
  {
    'id': 'fxai902',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai902' // implementation-routes作业路由id
  },
  {
    'id': 'fxai903',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai903' // implementation-routes作业路由id
  },
  {
    'id': 'fxai900',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai900' // implementation-routes作业路由id
  },
  {
    'id': 'fxai009',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai009' // implementation-routes作业路由id
  },
  {
    'id': 'fxai901',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai901' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi024',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi024' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi028',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi028' // implementation-routes作业路由id
  },
  {
    'id': 'fxii005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxii005' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi051',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi051' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi052',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi052' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq002' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq001' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq026',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq026' // implementation-routes作业路由id
  },
  {
    'id': 'fxed020',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed020' // implementation-routes作业路由id
  },
  {
    'id': 'fxed022',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed022' // implementation-routes作业路由id
  },
  {
    'id': 'fxed023',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed023' // implementation-routes作业路由id
  },
  {
    'id': 'fxed024',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed024' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd025',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd025' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq011' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd050',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd050' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd054',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd054' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd055',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd055' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd056',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd056' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd008',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd008' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd009',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd009' // implementation-routes作业路由id
  },
  {
    'id': 'fxbi022',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbi022' // implementation-routes作业路由id
  },
  {
    'id': 'fxbq022',  // 作业路由ID
    'type': '',
    'routerLink': '/fxbq022' // implementation-routes作业路由id
  },
  {
    'id': 'fxed020',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed020' // implementation-routes作业路由id

  },
  {
    'id': 'fxai008',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai008' // implementation-routes作业路由id
  },
  {
    'id': 'fxaq008',  // 作业路由ID
    'type': '',
    'routerLink': '/fxaq008' // implementation-routes作业路由id
  },
  {
    'id': 'fxaq017',  // 作业路由ID
    'type': '',
    'routerLink': '/fxaq017' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq034',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq034' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq063',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq063' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq065',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq065' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq062',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq062' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq061',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq061' // implementation-routes作业路由id
  },
  {
    'id': 'fxed013',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed013' // implementation-routes作业路由id
  },
  {
    'id': 'fxed014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed014' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq035',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq035' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq036',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq036' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq115',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq115' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq037',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq037' // implementation-routes作业路由id
  },
  {
    'id': 'fxhi006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxhi006' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq039',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq039' // implementation-routes作业路由id
  },
  {
    'id': 'fxed043',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed043' // implementation-routes作业路由id
  },
  {
    'id': 'fxed044',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed044' // implementation-routes作业路由id
  },
  {
    'id': 'fxed050',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed050' // implementation-routes作业路由id
  },
  {
    'id': 'fxed052',  // 作业路由ID
    'type': '',
    'routerLink': '/fxed052' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq011' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq012' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq013',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq013' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq014' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq015',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq015' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq016' // implementation-routes作业路由id
  },
  {
    'id': 'fxai016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai016' // implementation-routes作业路由id
  },
  {
    'id': 'fxai017',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai017' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq017',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq017' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq018',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq018' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq054',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq054' // implementation-routes作业路由id
  },
  {
    'id': 'fxrq001',  // 作业路由ID
    'type': '',
    'routerLink': '/fxrq001' // implementation-routes作业路由id
  },
  {
    'id': 'fxrq002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxrq002' // implementation-routes作业路由id
  },
  {
    'id': 'fxrq004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxrq004' // implementation-routes作业路由id
  },
  {
    'id': 'fxrq016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxrq016' // implementation-routes作业路由id
  },
  {
    'id': 'fxrq018',  // 作业路由ID
    'type': '',
    'routerLink': '/fxrq018' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq046',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq046' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq047',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq047' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq048',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq048' // implementation-routes作业路由id
  },
  {
    'id': 'fxeq049',  // 作业路由ID
    'type': '',
    'routerLink': '/fxeq049' // implementation-routes作业路由id
  },
  {
    'id': 'fxrq003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxrq003' // implementation-routes作业路由id
  },
  {
    'id': 'fxfd002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxfd002' // implementation-routes作业路由id
  },
  {
    'id': 'fxfd003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxfd003' // implementation-routes作业路由id
  },
  {
    'id': 'fxfd004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxfd004' // implementation-routes作业路由id
  },
  {
    'id': 'fxxi011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxi011' // implementation-routes作业路由id
  },
  {
    'id': 'fxxi012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxi012' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq005' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq041',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq041' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq006' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd021',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd021' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd022',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd022' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd023',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd023' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd026',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd026' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd028',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd028' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd029',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd029' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd002',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd002' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd061',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd061' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd062',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd062' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd063',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd063' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq014' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq011' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq016' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq007',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq007' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi017',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi017' // implementation-routes作业路由id
  },
  {
    'id': 'fxdi018',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdi018' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd013',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd013' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd003',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd003' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd038',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd038' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd004',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd004' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd006' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd081',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd081' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd084',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd084' // implementation-routes作业路由id
  },
  {
    'id': 'fxdd082',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdd082' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd011',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd011' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd012',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd012' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd037',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd037' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd013',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd013' // implementation-routes作业路由id
  },
  {
    'id': 'fxdq081',  // 作业路由ID
    'type': '',
    'routerLink': '/fxdq081' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd014',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd014' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd015',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd015' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd035',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd035' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq024',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq024' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq025',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq025' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd016',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd016' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd017',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd017' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd019',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd019' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd041',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd041' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd042',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd042' // implementation-routes作业路由id
  },
  {
    'id': 'fxxd043',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxd043' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq015',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq015' // implementation-routes作业路由id
  },
  {
    'id': 'fxai037',  // 作业路由ID
    'type': '',
    'routerLink': '/fxai037' // implementation-routes作业路由id
  },
  {
    'id': 'fxad037',  // 作业路由ID
    'type': '',
    'routerLink': '/fxad037' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq048',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq048' // implementation-routes作业路由id
  },
  {
    'id': 'fxxi005',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxi005' // implementation-routes作业路由id
  },
  {
    'id': 'fxxi006',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxi006' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq050',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq050' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq068',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq068' // implementation-routes作业路由id
  },
  {
    'id': 'fxxq069',  // 作业路由ID
    'type': '',
    'routerLink': '/fxxq069' // implementation-routes作业路由id
  },
];

